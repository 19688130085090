import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getListadoCompanias, getListadoRepresentantesSinSucursal, getListarRepresentantesXSucursal, listarConfiguracionRepresentanteIofe, registrarConfiguracionRepresentanteIofe } from "../../api/iofeApi";
import { inputSoloNumeros, obtenerLocalStorage } from "../../resources/funciones";
import Swal from "sweetalert2";
export const Iofe = () => {
    const { handleSubmit, register, reset, formState: { errors } } = useForm({});
    const [showModal, setShowModal] = useState(false);
    const [nombreRepresentante, setNombreRepresentante] = useState("");
    const [perIdSeleccionado, setPerIdSeleccionado] = useState(null);
    const [companias, setCompanias] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [empresaSeleccionada, setEmpresaSeleccionada] = useState('');
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState('');
    const [sucursalDescripcionSeleccionada, setSucursalDescripcionSeleccionada] = useState('');
    const [resultado, setResultado] = useState([]);
    const [isAgregarConfiguracion, setIsAgregarConfiguracion] = useState(false);
    const token = obtenerLocalStorage("usuario").token;
    const [showButton, setShowButton] = useState(false);
    const [dataRSinSurcursales, setDataRSinSurcursales] = useState([]);
    const handleEmpresaChange = (e) => {
        const empresa = e.target.value;
        setEmpresaSeleccionada(empresa);
        setSucursalSeleccionada('');
        setSucursalDescripcionSeleccionada('');
        const empresaSeleccionada = companias.find(compania => compania.value === empresa);
        setSucursales(empresaSeleccionada ? empresaSeleccionada.sucursales : []);
    };
    const handleSucursalChange = async (e) => {
        const sucursalValue = e.target.value;
        const sucursalSeleccionada = sucursales.find(sucursal => sucursal.value === sucursalValue);
        setSucursalSeleccionada(sucursalValue);
        setSucursalDescripcionSeleccionada(sucursalSeleccionada ? sucursalSeleccionada.description : '');
    };
    const handleOpenModal = async (data = {}, isAgregar = false) => {
        if (isAgregar) {
            setIsAgregarConfiguracion(true);
            setNombreRepresentante("");
            setPerIdSeleccionado(null);
            reset({
                UnFirmante: "",
                DosFirmantes: "",
                TresFirmantes: "",
                CuatroFirmantes: "",
                RepresentanteLegal: ""
            });
            const dataPost = {
                compania: empresaSeleccionada,
                sucursal: sucursalSeleccionada
            };
            const response = await getListadoRepresentantesSinSucursal(dataPost, token);
            if (response && response.data) {
                setDataRSinSurcursales(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } else {
            setIsAgregarConfiguracion(false);
            setNombreRepresentante(data.nombre);
            setPerIdSeleccionado(data.id);
            const dataPost = {
                sucursal: sucursalSeleccionada,
                representante: data.id
            };
            const response = await listarConfiguracionRepresentanteIofe(dataPost, token);
            if (response && response.data) {
                reset({
                    UnFirmante: response.data[0].value,
                    DosFirmantes: response.data[1].value,
                    TresFirmantes: response.data[2].value,
                    CuatroFirmantes: response.data[3].value,
                    RepresentanteLegal: data.representanteLegal || ""
                });
            } else {
                console.error("Error fetching data:", response.message);
            }
        }
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        reset();
    };
    const getCompanias = async () => {
        const { data } = await getListadoCompanias(token);
        setCompanias(data);
    };
    useEffect(() => {
        getCompanias();
    }, []);
    useEffect(() => {
        setShowButton(false);
    }, [empresaSeleccionada]);
    const buscar = async () => {
        if (!sucursalSeleccionada) {
            alert("Debe seleccionar una sucursal");
            return;
        }
        const dataPost = { "sucursal": sucursalSeleccionada };
        const { data, message } = await getListarRepresentantesXSucursal(dataPost, token);
        console.log(message);
        setResultado(data);
        setShowButton(true);
    };
    const onSubmit = async (formData) => {
        const configuracion = [
            { id: "1", value: formData.UnFirmante },
            { id: "2", value: formData.DosFirmantes },
            { id: "3", value: formData.TresFirmantes },
            { id: "4", value: formData.CuatroFirmantes },
        ];
        const dataPost = {
            sucursal: sucursalSeleccionada,
            representante: isAgregarConfiguracion ? formData.RepresentanteLegal : perIdSeleccionado,
            configuracion: configuracion
        };
        try {
            const registro = await registrarConfiguracionRepresentanteIofe(dataPost, token);
            setResultado(registro.data)             
            handleCloseModal();
            Swal.fire({
                title: "Hecho!",
                text: registro.message,
                icon: "success"
            });
        } catch (error) {
            console.error("Error al enviar la configuración:", error);
        }
    };
    return (
        <div className="container">
            <div className="pt-2">
                <h4 className=" mt-3 ">Configuracion de Representantes Legales en IOFE</h4>
            </div>
            <div className="container p-3">
                <div className="row">
                    <div className="col-4">
                        <select name="empresa" onChange={handleEmpresaChange} className="form-select" defaultValue={""}>
                            <option value="" disabled>Seleccione la compañía</option>
                            {companias.map((compania, index) => (
                                <option key={index} value={compania.value}>
                                    {compania.description}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <select name="sucursal" value={sucursalSeleccionada} onChange={handleSucursalChange} className="form-select">
                            <option value="" disabled>Seleccione la sucursal</option>
                            {sucursales.map((sucursal) => (
                                <option key={sucursal.value} value={sucursal.value}>
                                    {sucursal.description}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <button className="btn btn-outline-primary w-50" onClick={buscar}><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
            <div className="container p-3">
                {showButton && (
                    <button type="button" className="btn btn-success" onClick={() => handleOpenModal({}, true)}>
                        Agregar Configuración
                    </button>)}
            </div>
            <div className="container" >
                <div style={{ maxHeight: '400px', overflowY: 'auto', userSelect: "none" }}>
                    <table className="table text-center table-bordered" >
                        <thead>
                            <tr>
                                <th scope="col">Tipo de documento</th>
                                <th scope="col">N° Documento</th>
                                <th scope="col">Nombre Completo</th>
                                <th scope="col">Configuración</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultado.map((fila, index) => (
                                <tr key={index}>
                                    <td>{fila.doctipo === 'D' ? "DNI" : fila.doctipo === 'P' ? "Pasaporte" : "Carnet de extranjeria"}</td>
                                    <td>{fila.docnumero}</td>
                                    <td>{fila.nombre}</td>
                                    <td>
                                        <button className="btn btn-outline-primary" onClick={() => handleOpenModal(fila, false)}>
                                            <i className="bi bi-gear"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <>
                    <div className="modal-backdrop show"></div>
                    <div className="modal show" tabIndex="-1" style={{ display: "block" }} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Configuración de {nombreRepresentante.toLowerCase() || "un Representante"}</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {isAgregarConfiguracion && (
                                                <>
                                                    <div className="row mb-3">
                                                        <div className="col-5">
                                                            <label>Sucursal</label>
                                                        </div>
                                                        <div className="col-7">
                                                            <p>{sucursalDescripcionSeleccionada}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-5">
                                                            <label>Representante Legal</label>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-select" {...register('RepresentanteLegal', {
                                                                required: "Este campo es obligatorio"
                                                            })} >
                                                                <option value="" disabled selected>Seleccione un representante</option>
                                                                {dataRSinSurcursales.map((rep, index) => (
                                                                    <option key={index} value={rep.id}>{rep.nombre}</option>
                                                                ))}
                                                            </select>
                                                            {errors.RepresentanteLegal && <span className="text-danger">{errors.RepresentanteLegal.message}</span>}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="row">
                                                <div className="col-5">
                                                    <label>Un Firmante</label>
                                                </div>
                                                <div className="col-7">
                                                    <input type="text" className="form-control" onKeyDown={inputSoloNumeros} {...register('UnFirmante', {
                                                        validate: (value) => {
                                                            if (value.length > 6) {
                                                                return "El maximo es  6 caracteres"
                                                            }
                                                            return true
                                                        }
                                                    })} />
                                                    {errors.UnFirmante && <span className="text-danger">{errors.UnFirmante.message}</span>}
                                                </div>
                                                <div className="col-5 mt-2">
                                                    <label>Dos Firmantes</label>
                                                </div>
                                                <div className="col-7 mt-2">
                                                    <input type="text" className="form-control" onKeyDown={inputSoloNumeros} {...register('DosFirmantes', {
                                                        validate: (value) => {
                                                            if (value.length > 6) {
                                                                return "El maximo es  6 caracteres"
                                                            }
                                                            return true
                                                        }
                                                    })} />
                                                    {errors.DosFirmantes && <span className="text-danger">{errors.DosFirmantes.message}</span>}
                                                </div>
                                                <div className="col-5 mt-2">
                                                    <label>Tres Firmantes</label>
                                                </div>
                                                <div className="col-7 mt-2">
                                                    <input type="text" className="form-control" onKeyDown={inputSoloNumeros} {...register('TresFirmantes', {
                                                        validate: (value) => {
                                                            if (value.length > 6) {
                                                                return "El maximo es  6 caracteres"
                                                            }
                                                            return true
                                                        }
                                                    })} />
                                                    {errors.TresFirmantes && <span className="text-danger">{errors.TresFirmantes.message}</span>}
                                                </div>
                                                <div className="col-5 mt-2">
                                                    <label>Cuatro Firmantes</label>
                                                </div>
                                                <div className="col-7 mt-2">
                                                    <input type="text" className="form-control" style={{ marginBottom: "15px" }} onKeyDown={inputSoloNumeros} {...register('CuatroFirmantes', {
                                                        validate: (value) => {
                                                            if (value.length > 6) {
                                                                return "El maximo es  6 caracteres"
                                                            }
                                                            return true
                                                        }
                                                    })} />
                                                    {errors.CuatroFirmantes && <span className="text-danger">{errors.CuatroFirmantes.message}</span>}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="submit" className="btn btn-primary">Guardar cambios</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
